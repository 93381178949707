rb, rt {
  font-size: 1em;
  'z-index': -99;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

div.texteditor {
  font-family: "Roboto Mono", Tofu;
  font-optical-sizing: auto;
  font-weight: 400;
  border: none;
  outline: 0px;
  min-height: 100px;
  line-height: 3.5em;
  letter-spacing: normal;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1" height="56"><rect x="0" y="55" width="1" height="1" fill="%23adc7cc" /></svg>');
  background-repeat: repeat;
  background-size: 1px 56px;
}

span.texteditor.missing {
  border: 2px dashed;
  border-radius: 8px;
  padding: 0px 8px;
  min-width: 200px;
  height: 2em;
  margin: 8px;
  vertical-align: middle;
  font-size: 1em;
  line-height: 1.8em;
  display: inline-block;
}

:root, :host {
  --error-important-underline: var(--red);
  --error: #F3D3D9;
  --highlight: #FEEFC4DD;
}

span.texteditor.missing:empty:focus::before,
span.texteditor.missing:empty::before{
  content:attr(data-placeholder);
  color:gray
}

span.clickable {
  text-decoration: underline;
  text-decoration-color: var(--error);
  -webkit-text-decoration-color: var(--error);
  cursor: pointer;
  scroll-margin-top: 70px;
}
span.clickable.important {
  text-decoration: underline;
	text-decoration-style:wavy;
  -webkit-text-decoration-style:wavy;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--error-important-underline);
  -webkit-text-decoration-color: var(--error-important-underline);
  cursor: pointer;
}
span.clickable:hover {
  background: linear-gradient(to right, transparent, var(--highlight) 2%, var(--highlight) 98%, transparent);
}
span.clickable.selected {
  background: linear-gradient(to right, transparent, var(--highlight) 2%, var(--highlight) 98%, transparent);
}
span.clickable.hovered {
  background: linear-gradient(to right, transparent, var(--highlight) 2%, var(--highlight) 98%, transparent);
}

span.clickable.important:hover {
  background: linear-gradient(to right, transparent, var(--highlight) 2%, var(--highlight) 98%, transparent);
}
span.clickable.important.selected {
  background: linear-gradient(to right, transparent, var(--highlight) 2%, var(--highlight) 98%, transparent);
}
span.clickable.important.hovered {
  background: linear-gradient(to right, transparent, var(--highlight) 2%, var(--highlight) 98%, transparent);
}

.popover.correction-menu {
  background: rgba(0, 0, 0, 0);
  border-width: 0px;
  box-shadow: 0px 0px 0px 0px;
}

#texteditor:empty:focus::before,
#texteditor:empty::before {
  content:attr(placeholder);
  color:grey;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.uguis-editor-menu {
  background: rgba(0, 0, 0, 0);
  border-width: 0px;
  box-shadow: 0px 0px 0px 0px;
  position: absolute;
  z-index: 998;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  /*transition: opacity 0.5s;*/
}

.u-editor {
  /*white-space: collapse;*/
}

.u-group {
  display: inline-block;
  word-wrap: normal;
  /*white-space: collapse;*/
  white-space-collapse: normal;
}

.u-tag-ruby{
  ruby-position: under
}

.u-tag-rt{
  text-align: left
}

.u-tag {
  position: absolute;
  opacity: 0.75;
  top: 35px;
  left: -5px;
}

.u-placeholder {
  position: relative;
  text-wrap: auto;
  word-break: break-word;
  white-space-collapse: break-spaces;
}

.u-sentence{
  /*white-space: pre-wrap;*/
  display: inline;
  margin-right: 8px;

  /*border: 2px dashed red;*/
  /*border-radius: 8px;*/
}

.u-editable {
  user-select: all;
  white-space: normal;
}

.u-editable::selection {
  background-color: transparent;
}

.u-leaf {
  text-wrap: auto;
  word-break: break-word;
  white-space: pre-wrap;
}
