.article {
    font-size: 1.5rem;
    margin-top: 1.5rem;
}

.content {
}

.separation {
  margin-top: 1em;  
}


.indentation {
  text-indent: 1em;
}

.ul_disc li{
  list-style-type: disc;
}

.list_parentheses{
  padding:0 0 0 0;
  margin-left: 2em;
}
.list_parentheses li{
  list-style-type:none;
  list-style-position:inside;
  counter-increment: cnt;
  
}
.list_parentheses li:before{
  display:inline-block;
  content: "(" counter(cnt) ") ";
  margin-left: -1.5em;
  width: 1.5em;
}
