:root, :host {
  --vocabulary-light: #D6F5DE;
  --grammar-light: #C8EFFD;
  --content-light: #FCE8AB;
  --coherence-light: #FFC372;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.article {
  color:#222;
  font-weight:normal;
  font-size: 18px;
  white-space: pre-wrap;
}

.article > p {
  margin-top: 0;
  margin-bottom: 0;
  text-indent: 2em;
}

.email {
  color:#222;
  font-family:Courier, monospace;
  font-weight:normal;
  font-size:16px;
}

.email-reply {
  font-style:italic;
}

.card {
   box-shadow: none;
}

.card-header {
  background-color: var(--mid-green);
  height: 56px;
}

@media only screen and (max-width: 768px) {
  .card-header {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.card-header.answer {
  background-color: var(--yellow);
}

.card-header.chat {
  background-color: var(--green);
  padding: 5px;
}

.card-header.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
  background: white;
}

.feedback {
  border: 1px solid var(--border);
  cursor: pointer;
}

.badge-vocabulary {
  background-color: var(--vocabulary-light) !important;
  color: black;
}

.badge-grammar {
  background-color: var(--grammar-light) !important;
  color: black;
}

.badge-content {
  background-color: var(--content-light) !important;
  color: black;
}

.badge-coherence {
  background-color: var(--coherence-light) !important;
  color: black;
}

.feedback.unselected {
  opacity: 0.3;
  filter: grayscale(100%);
  transition: all 100ms ease-in-out 0ms;
  transition-property: all;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  transition-delay: 0ms;
}

.feedback:hover {
  opacity: 1.0;
  filter: grayscale(0%);
}

.feedback.selected {
  opacity: 1.0;
  filter: grayscale(0%);
}

.feedback.hovered {
  /* to be used when corresponding items are hovered */
  opacity: 1.0;
  filter: grayscale(0%);
}

.container.feedback-sticky-container {
  height: calc(100% - 166px);
  height: -o-calc(100% - 166px); /* opera */
  height: -webkit-calc(100% - 166px); /* google, safari */
  height: -moz-calc(100% - 166px); /* firefox */
}

.feedback-pane {
  top: 0;
  position: sticky;
  max-height: 100vh;
}

.feedback-card-pane {
  position: relative;
  overflow: auto;
  max-height: 100vh;
}

@media only screen and (max-width: 768px) {
  .feedback-card {
    overflow: auto;
    max-height: 100px;
  }
}

@media (min-width: 768px) {
  .tile {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px;
    margin: 8px;
  }
  .new-tile {
    width: 180px;
    height: 180px;
    padding: 0px;
    margin: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .tile {
    width: 95%;
    padding: 0px;
    margin: 8px auto;
  }
}


.fade-text {
  max-height: 64px;
  overflow: hidden;
  position: relative;
}

.fade-text:before {
  content:'';
  width:100%;
  height: 32px;
  position:absolute;
  left:0;
  top:32px;
  background:linear-gradient(transparent 0, white 100%);
}

.tile-footer {
  height: 2em;
  border: none;
}

.feedback-footer {
  border: none;
}

.assignment-title-pane {
  z-index: 100; /* to make sticky title under the pulldown menu */
  background:linear-gradient(var(--light-green), var(--light-green), var(--light-green), transparent);
}

.empty-placeholder {
  height: calc(100vh - 166px);
  height: -o-calc(100vh - 166px); /* opera */
  height: -webkit-calc(100vh - 166px); /* google, safari */
  height: -moz-calc(100vh - 166px); /* firefox */
}

.striking {
  color: var(--orange);
}

.speech-bubble {
  background-color: white;
  border-radius: 20px;
  position: relative;
  margin-bottom: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  --padding-x: 2em;
  padding-right: var(--padding-x);
  padding-left: var(--padding-x);
  --padding-y: 0.5em;
  padding-top: var(--padding-y);
  padding-bottom: var(--padding-y);
  width: max-content;
  line-height: 1.2;
}

.speech-bubble:before {
  content: "";
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top: 10px solid white;
}

.strong {
  font-weight: 900;
}

.catchcopy {
  text-align: center;
  border-radius: 20px;
  padding: 5px;
  width: 110px;
  height: 90px;
  background: #FFFCF7;
  vertical-align: middle;

  display: flex;
  justify-content: center;
  align-items: center;
}

.subscript {
  font-size: 14px;
}

.subtitle {
  font-size: 17px;
}

.title {
  font-size: min(6vw, 32px);
}
@media only screen and (min-width: 576px) {
  .subscript {
    font-size: 15px;
  }
  .subtitle {
    font-size: 19px;
  }
  .catchcopy {
    width: 130px;
    height: 110px;
  }
}
@media only screen and (min-width: 768px) {
  .subscript {
    font-size: 14px;
  }
  .subtitle {
    font-size: 17px;
  }
  .catchcopy {
    width: 110px;
    height: 90px;
  }
}
@media only screen and (min-width: 992px) {
  .subscript {
    font-size: 15px;
  }
  .subtitle {
    font-size: 21px;
  }
  .catchcopy {
    width: 135px;
    height: 115px;
  }
}

.green-text {
  color: var(--green);
}

.white-text {
  color: #FFFFFF;
}

.red-text {
  color: var(--red);
}

.green-page {
  background: var(--green);
}

.mid-green-page {
  background: var(--mid-green);
}

.white-page {
  background: white;
}

.compact-section {
  font-size: 1.1em;
  display: inline-block;
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  .usage {
    font-size: 1em;
    padding: 20px 20px;
  }
  .compact-section {
    font-size: 1em;
  }
}

.nav-tabs {
  border-radius: 50vh;
  padding: 5px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.nav-item .nav-link {
  border-radius: 50vh;
  border-width: 0px !important;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
}

.nav-item .nav-link.active {
  color: white;
  background-color: var(--green);
}

.tab-header {
  z-index: 98;
}

.supposed-time-badge {
  color: var(--mdb-body-color) !important;
}

.card-padding-mobile .card{
  padding-left: calc(var(--mdb-gutter-x)*.5);
  padding-right: calc(var(--mdb-gutter-x)*.5);
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}


.text-xs {
  font-size: 0.75rem;
}

.chatloading {
  font-weight: bold;
  display:inline-block;
  font-family: monospace;
  clip-path: inset(0 3ch 0 0);
  animation: l 1.5s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.modal.fade {
  z-index: 10000000 !important;
}

.layout-splitter {
  height: 10px !important;
}

.chat-card {
  background-color: white;
  border: 1px solid var(--green);
  color: var(--green);
  display: flex;
  flex-direction: column;
  margin: 8px;
  border-radius: 50vh;
}

.ai-chat {
  border-radius: 0px 25px 25px 25px;
  background-color: var(--light-green);
}

.user-chat {
  background-color: var(--light-green);
  border-radius: 25px 0px 25px 25px;
}

.left-vr {
  border-left: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #fff, var(--border), #fff);
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}

.left-vr.thick {
  border-left: 2px solid;
}

.top-vh {
  border-top: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #fff, var(--border), #fff);
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.top-vh.thick {
  border-top: 2px solid;
}

.dropdown-toggle.revision {
  text-align: left;
  background: white;
  color: black;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: none;
  height: 41px;
  border: 0px;
}

.dropdown-toggle.revision:focus {
  color: black;
  background: var(--light-gray);
  box-shadow: none!important;
}

.dropdown-toggle.revision:hover {
  box-shadow: none!important;
}

.dropdown-toggle.revision:active:focus {
  color: black;
  background: var(--light-gray);
  box-shadow: none;
}

.revision-dropdown-item {
  font-size: 14px;
  font-weight: bold;
}

.revision-icon {
  color: white;
  background: var(--green);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 40px;
  height: 24px;
}

.dropdown-menu.revision {
  border-radius: 10px;
  /* border: 1px solid var(--green); */
  box-shadow: 0px 4px 9px -4px rgba(0, 0, 0, 0.2);
}

#chatinput {
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px solid var(--light-gray);
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#chatinput:focus {
  border: 3px solid var(--light-gray);
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 4px;
  padding-bottom: 4px;
}

#chatinput::-webkit-resizer {
  display: none;
}

.chat-popup {
  position: fixed;
  min-width: 400px;
  max-width: 62vw;
  bottom: -2px; /* 縁無し */
  right: 50px;
  box-shadow: 0px 4px 9px -4px rgba(0, 0, 0, 0.2);
}

.chat-popup-content {
  max-height: 90vh;
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

.breadcrumb {
  line-height:41px;
}

.breadcrumb-item {
  height: 41px;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+);
}
