.privacyPolicy{
  h2{
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 80px;
    margin-bottom: 20px;
  }
  h3{
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  h4{
    font-size: 1rem;
    font-weight: normal;
  }
  hr{
    margin: 40px 0;
  }
  
  .docInfo {
    text-align: right;
    margin-bottom: 20px;
  }
  
  .introMessage {
    margin-bottom: 20px;
  }
  
  .contactInfo {
    margin-bottom: 20px;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
  }
  .contactInfo dl{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0;
  }
  
  .decimal {
    list-style-type: decimal;
    margin-left: 20px;
  }
  
  .decimalLeadingZero {
    list-style-type: decimal-leading-zero;
    margin-left: 20px;
  }
  
  .privacyLowerLatin {
    list-style-type: lower-latin;
    margin-left: 20px;
  }
  
  .marker {
    list-style-type: disc;
    margin-left: 20px;
  }
}
